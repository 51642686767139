import { Fullscreen } from '../fullscreen/fullscreen';
import { Background } from '../background/background';

import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'reactstrap';
import { PRIMARY_TEXT_COLOR, TEXT_FONT, TITLE_FONT } from '../../constants';
import { Icon } from '../shared';

const PersonTitleContainer = styled(Container)`
    z-index: 1;
    margin-bottom: 100px;
`;

const PersonTitle = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 240px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.61;
    letter-spacing: -14.9px;
    color: ${PRIMARY_TEXT_COLOR};
    text-indent: -20px;
    margin-bottom: 40px;

    @media (max-width: 992px) {
        font-size: 100px;
    }

    @media (max-width: 768px) {
        font-size: 72px;
        text-indent: 0;
        letter-spacing: inherit;
    }
`;

const PersonPosition = styled.h3`
    font-family: ${TEXT_FONT};
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: ${PRIMARY_TEXT_COLOR};

    @media (max-width: 992px) {
        font-size: 28px;
    }
`;

const Social = styled.div`
    a {
        color: ${PRIMARY_TEXT_COLOR};
        font-size: 44px;
    }

    @media (max-width: 992px) {
        a {
            font-size: 32px;
        }
    }

    a:hover {
        text-decoration: none;
    }

    a::before {
        margin-left: 0;
    }
`;

const PersonSocial = ({ facebook, linkedin, email, twitter }) => {
    return (
        <Social>
            {facebook ? <Icon type="facebook" href={facebook} /> : null}
            {twitter ? <Icon type="twitter" href={twitter} /> : null}
            {linkedin ? <Icon type="linkedin" href={linkedin} /> : null}
            {email ? <Icon type="email" href={email} /> : null}
        </Social>
    );
};

export const PersonHeading = ({ person }) => {
    return (
        <Fullscreen height="84vh" className="d-flex justify-content-end flex-column position-relative">
            <Background bg={person.cover} design={'cover'} index={1} />
            <PersonTitleContainer>
                <Row>
                    <Col>
                        <PersonTitle>{person.name}</PersonTitle>
                        <PersonPosition>{person.title}</PersonPosition>
                        <PersonSocial {...person.social} />
                    </Col>
                </Row>
            </PersonTitleContainer>
        </Fullscreen>
    );
};
