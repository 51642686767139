import Layout from '../../components/layout/layout';
import { PersonHeading } from '../../components/people/person-heading';
import { TextBlock } from '../../components/shared/text-block.jsx';

import { LUKA, PRIMARY_TEXT_COLOR } from '../../constants';
import React from 'react';
import { Container } from 'reactstrap';
import { Wrapper } from '../../components/shared/wrapper';

export default () => (
    <Layout>
        <PersonHeading person={LUKA} />
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <TextBlock title="Bio.">
                    <p>
                        Luka founded and holds the role of CEO of Equaleyes, a digital agency based across London, UK and Maribor, Slovenia.
                        Equaleyes builds mobile and web digital products - developing, managing and successfully maintaining technology with
                        millions of monthly users.
                    </p>
                    <p>
                        Luka's approach to business is simple but strong. He counts client success and the employee happiness as the two
                        metrics he is most passionate about. And the numbers speak for themselves. By providing high-quality service and
                        deeply caring for Equaleyes clients and employees, Equaleyes been able to grow an amazing 10x in revenue from 2014 -
                        2017, while having low employee and client turnaround.
                    </p>
                    <p>
                        Luka is constantly striving to keep in tension the best price Equaleyes can offer their clients and the value we can
                        then provide. Once someone becomes part of the Equaleyes family either as a client, a partner or an employee - they
                        know they can always count on Equaleyes. Equaleyes will have your back.
                    </p>
                    <p>
                        Luka's background is within the technical and business arenas. He holds a Master's degree in computer science and
                        was placed within Telefonica's corporate business accelerator programme Wayra for his first company alongside his
                        co-founders. He has also previously co-founded and exited a startup called Equaleyes Accessibility. He also holds
                        multiple awards for his contributions and involvement in world-class hackathons over the years, the detail of which
                        can be found listed below.
                    </p>
                    <p>
                        Committed to providing value wherever he can, Luka works as a business advisor, provides introductions to his
                        network or seeks to contribute with products that he leads.
                    </p>
                    <p>
                        Luka is a regular on the European mentoring circuit, and has become a mentor to several fledgeling startups. He is a
                        frequent speaker at business and technology events throughout Europe.
                    </p>
                </TextBlock>
            </Container>
        </Wrapper>
    </Layout>
);
